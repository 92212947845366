import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['teams']

  connect() {
    this.toggle()
  }

  toggle() {
    this.teamsTarget.classList.toggle('d-none')
  }
}
