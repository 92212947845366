import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['companyNumberInput', 'companyNumber', 'companyName', 'companyFullAddress']

  selectCorporateNumber(event) {
    let dataset = event.currentTarget.dataset
    this.companyNumberInputTarget.value = dataset.companyNumber

    if (this.hasCompanyNumberTarget) {
      this.companyNumberTarget.innerHTML = dataset.companyNumber
    }
    if (this.hasCompanyNameTarget) {
      this.companyNameTarget.innerHTML = dataset.companyName
    }
    if (this.hasCompanyFullAddressTarget) {
      this.companyFullAddressTarget.innerHTML = dataset.companyFullAddress
    }
    $('#companyNumberSearchModal').modal('hide')
  }
}
