import { Controller } from '@hotwired/stimulus'
import axios from 'axios'
import Chart from 'chart.js'

export default class extends Controller {
  static values = { graphPath: String }

  connect() {
    this._renderGraph()
  }

  _renderGraph() {
    axios.get(this.graphPathValue)
      .then((response) => {
        let ctx = this.element.getContext('2d')
        new Chart(ctx, response.data)
      })
  }
}
