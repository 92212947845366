// IE11で@rails/ujsを正常に動作させるために追加
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import jQuery from 'jquery'
window.jQuery = jQuery
window.$ = jQuery

import Popper from 'popper.js'
import 'bootstrap'

import Rails from '@rails/ujs'
import { Turbo } from '@hotwired/turbo-rails'
Turbo.session.drive = false

import './controllers'

Rails.start()

// Images
// require.context('images', true, /\.(png|gif|jpg|jpeg|svg)$/)

// ActionText
import 'trix'
import '@rails/actiontext'
