import { Controller } from '@hotwired/stimulus'
import axios from 'axios'

export default class extends Controller {
  static values = {　pollingPath: String　}
  static targets = ['alert']

  connect() {
    setInterval(this._polling.bind(this), 5000)
  }

  _polling() {
    axios.get(this.pollingPathValue)
      .then((response) => {
        if(response.data.comments.length > 0) {
          this.alertTarget.classList.remove('d-none')
        }
      })
  }
}
