import { Application } from '@hotwired/stimulus'

const application = Application.start()

// Configure Stimulus development experience
application.warnings = process.env.NODE_ENV === 'development'
application.debug = process.env.NODE_ENV === 'development'
window.Stimulus = application

export { application }
